.main-content-gallery {
    background-color: #EEE;
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
}

.gallery-image-container {
    margin: auto;
    width: 60%;
}

.gallery-image {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width: 900px) {
    .gallery-items-container {
        width: 95%;
    }
    .gallery-image-container {
    margin: auto;
    width: 90%;
    height: auto;
}
}