.main-content-reviews {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #eee;
}

.review-items-container {
    width: 80%;
    display: flex;
    margin: auto;
    padding: 20px 0;
    /* background-color: blue; */
}

.review-item {
    text-align: left;
    /* min-width: 300px; */
    /* max-width: 500px; */
    margin: 0px 10px 20px 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 10px 1px #ddd;
    border-radius: 10px;
}

.review-item h4 {
    text-align: right;
}

@media screen and (max-width: 900px) {
    .review-items-container {
        width: 95%;
    }
}