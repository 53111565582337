.main-content-contact {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #eee;
}

.contact-items-container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.contact-item {
    padding: 20px;
}

.contact-button {
    text-decoration: none;
    color: rgb(0, 110, 255);
}

.contact-image {
    font-size: 2rem;
    padding-bottom: 10px;
}

@media screen and (max-width: 400px) {
    .contact-items-container {
        display: flex;
        justify-content: center;
        margin: auto;
        flex-direction: column;
    }
}