.main-content-about {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #0076BA;
    color: white;
}

.about-content {
    width: 70%;
    margin: auto;
}

.about-image-container {
    margin: auto;
    width: 200px;
    height: 200px;
    background-color: #004D80;
    border-radius: 50%;
    box-shadow: 1px 1px 20px #004D80;
}

.about-content-body {
    font-size: 1.1rem;
}

@media screen and (max-width: 900px) {

.about-content {
    width:90%;
    margin: auto;
}

}