.main-nav-container {
    position: sticky;
    width: 100%;
    height: 75px;
    background-color: #0076BA;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-left-container {
    padding-left: 20px;
}

.navbar-right-container {
    padding-right: 20px;
    display: flex;
}

.navbar-list-item {
    display: inline-block;
    margin: 0px 10px;
}

.navbar-main-title {
    color: white;
}

.navbar-button {
    padding: 15px;
    text-decoration: none;
    color: white;
}

.navbar-button:hover {
    border-radius: 5px;
    background-color: #56C1FF55;
    transition: 0.3s;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 700px) {
    .navbar-right-container {
        display: none;
    }

    .menu-icon {
        display: block;
        padding-right: 20px;
    }

    .navbar-right-container.active {
        background-color: #0076BA;
        width: 100%;
        padding: 5px 0;
        display: flex;
        position: absolute;
        transform: translateY(140px);
        flex-direction: column;
        align-items: center;
    }
    .navbar-list-item {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }

    .fas.fa-times, .fas.fa-bars {
        font-size: 1.3rem;
        color: white;
    }
}