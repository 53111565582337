@import url(https://fonts.googleapis.com/css?family=Avenir);
* {
    font-family: Avenir, Halvetica, Arial, sans-serif;
    margin: 0;
    scroll-behavior: smooth;
}
.main-nav-container {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: 75px;
    background-color: #0076BA;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-left-container {
    padding-left: 20px;
}

.navbar-right-container {
    padding-right: 20px;
    display: flex;
}

.navbar-list-item {
    display: inline-block;
    margin: 0px 10px;
}

.navbar-main-title {
    color: white;
}

.navbar-button {
    padding: 15px;
    text-decoration: none;
    color: white;
}

.navbar-button:hover {
    border-radius: 5px;
    background-color: #56C1FF55;
    transition: 0.3s;
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 700px) {
    .navbar-right-container {
        display: none;
    }

    .menu-icon {
        display: block;
        padding-right: 20px;
    }

    .navbar-right-container.active {
        background-color: #0076BA;
        width: 100%;
        padding: 5px 0;
        display: flex;
        position: absolute;
        -webkit-transform: translateY(140px);
                transform: translateY(140px);
        flex-direction: column;
        align-items: center;
    }
    .navbar-list-item {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }

    .fas.fa-times, .fas.fa-bars {
        font-size: 1.3rem;
        color: white;
    }
}
.main-content-intro {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    /* background-color: #eee; */
    background-image: linear-gradient(#0076BA, #004D80);
}

div.main-content-intro h1 {
    font-size: 3rem;
    text-transform: uppercase;
    color: #eee;
}
div.main-content-intro h3 {
    font-size: 2rem;
    padding-top: 10px;
    font-weight: normal;
    color: #eee;

}

div.main-content-intro p {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 20px;
    font-style: italic;
    color: rgb(127, 179, 209);
}

@media screen and (max-width: 900px) {
    div.main-content-intro h1 {
        font-size: 2rem;
        padding: 0px 5px;
        color: #eee;
    }
    div.main-content-intro h3 {
        font-size: 1.2rem;
        padding-top: 10px;
        padding: 10px 5px;
        font-weight: normal;
        color: #eee;
    }
    div.main-content-intro p {
        font-size: 1.1rem;
        padding-top: 20px;
        padding: 0px 5px;
        font-style: italic;
        color: rgb(127, 179, 209);
    }
}
.main-content-results {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
}

.results-year-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-more-button {
    margin: auto;
    border: none;
    color: cornflowerblue;
    background-color: #fff;
    font-size: 1.1em;
    transition: ease 0.5s;
    cursor: pointer;
}

.results-year-select-item {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #888;
    transition: ease 0.5s;
    cursor: pointer;
}

.results-year-select-item.selected {
    background-color: #0076BA;
    color: white;
}

.marks-container {
    padding: 10px;
    width: 40%;
    margin: auto;
}

.marks-container h3 {
    color: cornflowerblue;
    margin-right: 20px;
}

.marks-container-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    padding: 10px;
}

@media screen and (max-width: 700px) {
    .marks-container {
        padding: 10px;
        width: 90%;
        margin: auto;
    }
}
.main-content-contact {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #eee;
}

.contact-items-container {
    display: flex;
    justify-content: center;
    margin: auto;
}

.contact-item {
    padding: 20px;
}

.contact-button {
    text-decoration: none;
    color: rgb(0, 110, 255);
}

.contact-image {
    font-size: 2rem;
    padding-bottom: 10px;
}

@media screen and (max-width: 400px) {
    .contact-items-container {
        display: flex;
        justify-content: center;
        margin: auto;
        flex-direction: column;
    }
}
.main-content-reviews {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #eee;
}

.review-items-container {
    width: 80%;
    display: flex;
    margin: auto;
    padding: 20px 0;
    /* background-color: blue; */
}

.review-item {
    text-align: left;
    /* min-width: 300px; */
    /* max-width: 500px; */
    margin: 0px 10px 20px 10px;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 10px 1px #ddd;
    border-radius: 10px;
}

.review-item h4 {
    text-align: right;
}

@media screen and (max-width: 900px) {
    .review-items-container {
        width: 95%;
    }
}
.main-content-gallery {
    background-color: #EEE;
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
}

.gallery-image-container {
    margin: auto;
    width: 60%;
}

.gallery-image {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width: 900px) {
    .gallery-items-container {
        width: 95%;
    }
    .gallery-image-container {
    margin: auto;
    width: 90%;
    height: auto;
}
}
.main-content-about {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: #0076BA;
    color: white;
}

.about-content {
    width: 70%;
    margin: auto;
}

.about-image-container {
    margin: auto;
    width: 200px;
    height: 200px;
    background-color: #004D80;
    border-radius: 50%;
    box-shadow: 1px 1px 20px #004D80;
}

.about-content-body {
    font-size: 1.1rem;
}

@media screen and (max-width: 900px) {

.about-content {
    width:90%;
    margin: auto;
}

}
.footer-container {
    background-color: #eee;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    color: #666;
    background-color: #ddd;
}
