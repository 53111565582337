.main-content-intro {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
    /* background-color: #eee; */
    background-image: linear-gradient(#0076BA, #004D80);
}

div.main-content-intro h1 {
    font-size: 3rem;
    text-transform: uppercase;
    color: #eee;
}
div.main-content-intro h3 {
    font-size: 2rem;
    padding-top: 10px;
    font-weight: normal;
    color: #eee;

}

div.main-content-intro p {
    font-size: 1.5rem;
    padding-top: 10px;
    padding-bottom: 20px;
    font-style: italic;
    color: rgb(127, 179, 209);
}

@media screen and (max-width: 900px) {
    div.main-content-intro h1 {
        font-size: 2rem;
        padding: 0px 5px;
        color: #eee;
    }
    div.main-content-intro h3 {
        font-size: 1.2rem;
        padding-top: 10px;
        padding: 10px 5px;
        font-weight: normal;
        color: #eee;
    }
    div.main-content-intro p {
        font-size: 1.1rem;
        padding-top: 20px;
        padding: 0px 5px;
        font-style: italic;
        color: rgb(127, 179, 209);
    }
}