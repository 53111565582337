.main-content-results {
    margin: auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
}

.results-year-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.show-more-button {
    margin: auto;
    border: none;
    color: cornflowerblue;
    background-color: #fff;
    font-size: 1.1em;
    transition: ease 0.5s;
    cursor: pointer;
}

.results-year-select-item {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #888;
    transition: ease 0.5s;
    cursor: pointer;
}

.results-year-select-item.selected {
    background-color: #0076BA;
    color: white;
}

.marks-container {
    padding: 10px;
    width: 40%;
    margin: auto;
}

.marks-container h3 {
    color: cornflowerblue;
    margin-right: 20px;
}

.marks-container-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    padding: 10px;
}

@media screen and (max-width: 700px) {
    .marks-container {
        padding: 10px;
        width: 90%;
        margin: auto;
    }
}